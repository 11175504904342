<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法全面上线国融证券
              </p>
              <div class="fst-italic mb-2">2024年10月11日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国融证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/90/01-国融上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，非凸智能底仓增强算法及拆单算法顺利上线国融证券，全面满足投资者在多种终端上的智能交易服务需求。双方项目组通力合作，突破技术卡点，显著提升交易智能化能力，为投资者带来更加高效、便捷、稳定的交易体验。
                  </p>
                  <p>
                    国融证券拥有夯实的投研基础、广泛的客户群、专业的销交团队和先进的交易理念，致力于为客户提供投资银行、自营投资、资产管理、经纪业务等综合性金融产品与服务。国融证券以数字化手段洞察客户需求、智能化技术提升客户体验，携手非凸科技全面满足投资者对算法策略的多样化需求，切实推动普惠金融的实现。
                  </p>
                  <p>
                    随着市场交易量的快速增长以及交易活跃度的提升，广大投资者对底仓增强交易的自动化需求日益迫切。此次，国融证券联合非凸科技采用轻量化H5兼容模式，投资者可在国融兜兜满APP上直接操作，充分支持个人用户的算法签约和交易管理。针对不同市场行情，非凸底仓增强算法利用机器学习等人工智能技术，自动抓取日内行情波动进行高抛低吸，能有效降低持仓成本，获取风险可控的超额收益。
                  </p>
                  <p>
                    此外，国融证券通过非凸智能交易终端，满足了投资者通过智能拆单算法，降低交易成本、优化交易执行、提升交易效率等需求。交易量增大的行情下，非凸智能交易终端支持多节点交易、快速扩容，以及仿真大规模交易行情模拟压测，同时可以为券商提供相应配套的交易工具。
                  </p>
                  <p>
                    未来，非凸科技将继续携手国融证券等合作伙伴，不断提升自主创新能力和服务质量，助力券商更好地开展业务，满足广大投资者多元化需求，共同推动资本市场的繁荣发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News90",
};
</script>

<style></style>
